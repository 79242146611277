import useLocalStorage from './useLocalStorage';
import useSessionStorage from './useSessionStorage';

const USER_KEY = 'TS-S-USER';

//Verify if this approach is the one that we're gonna use.
export default function useToken() {
  const [localUser, setLocalUser] = useLocalStorage(USER_KEY, undefined);
  const [sessionUser, setSessionUser] = useSessionStorage(
    USER_KEY,
    undefined
  );

  const user = localUser ?? sessionUser;
  const setUser = (user: string | undefined, localStorage = false) => {
    localUser || localStorage ? setLocalUser(user) : setSessionUser(user);
  };
  const isLoggedIn = user !== undefined;

  return { user, setUser, isLoggedIn };
}
