import { lazy } from 'react';
import { Loadable } from '../../berry/components';
const ROUTE_PREFIX = '/pagos';
const ROUTE_PREFIX_ACTUALIZAR = '/actualizarpago';

const Pago = Loadable(lazy(() => import('../../pages/Pago/Pago')));
const ActualizarPago = Loadable(lazy(() => import('../../pages/Pago/ActualizarPago')));

const PagoRoutes = [
  {
    path: `${ROUTE_PREFIX}`,
    element: <Pago />,
    title: 'Pagos',
  },
  {
    path: `${ROUTE_PREFIX}/:id`,
    element: <Pago />,
    title: 'Pagos',
  },
  {
    path: `${ROUTE_PREFIX_ACTUALIZAR}`,
    element: <ActualizarPago />,
    title: 'Actualizar Pagos',
  },
];

export default PagoRoutes;
