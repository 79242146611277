import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { store, persister } from './berry/store/store';
import { createRoot } from 'react-dom/client';
/**
 * Important: We must wrap the entire application in a StrictMode component to prevent issues
 * <StrictMode>
 * </StrictMode>
 */
import './assets/scss/style.scss';

import App from './App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
