import * as Routes from './dashboardRoutes/';

const namedPages = [
  ...Routes.Inicio,
  ...Routes.Clients,
  ...Routes.Predio,
  ...Routes.Pagos,
  ...Routes.Reportes,
  ...Routes.Venta,
  ...Routes.Permisos
];

export default function getPageTitle(location: string): string {
  //TODO Verify this for location with parameters
  const currentPage = namedPages.find((p) => p.path === location);
  if (currentPage) {
    return currentPage.title;
  }

  const newName = location.replace(/(\n)/gm,"")
 
  if(newName.includes('pagos')){
    return "Pagos";
  }

  return `No name for page at ${location}`;
}
