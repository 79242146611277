import { HttpResponse } from "../utils/httpRespose";
import { IUsuario } from "./dto/PermisoDto";
import { IUsuarioRol } from "./dto/UserDto";
import { get, put } from "./FetchApi";

export const getPermisoUsuario = (
  url: string
): Promise<HttpResponse<IUsuario>> => get<IUsuario>(url);
export const getUsuarios = (
  url: string
): Promise<HttpResponse<IUsuarioRol[]>> => get<IUsuarioRol[]>(url);
export const getUsuario = (url: string): Promise<HttpResponse<IUsuarioRol>> =>
  get<IUsuarioRol>(url);
export const putPermiso = (
  url: string,
  requestBody: IUsuarioRol
): Promise<HttpResponse<IUsuarioRol>> =>
  put<IUsuarioRol, IUsuarioRol>(url, requestBody);
