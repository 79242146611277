import { styled, CustomTheme } from '@mui/material/styles';
import { Constants } from '../berry/store/';

export interface MainStyleProps {
  theme: CustomTheme;
  open: boolean;
}

export const DashboardStyle = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }: MainStyleProps) => {
  const { drawerWidth, minimizedDrawerWidth } = Constants;

  const currentDrawerWidth = open ? drawerWidth : minimizedDrawerWidth;

  return {
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        width: `calc(100% - ${currentDrawerWidth}px)`
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        width: `calc(100% - ${currentDrawerWidth}px)`,
        padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${currentDrawerWidth}px)`,
        padding: '16px',
        marginRight: '10px'
      }
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${currentDrawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px'
      }
    }),
    ...(!open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${currentDrawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px'
      }
    })
  };
});
