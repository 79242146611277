/* eslint-disable react-hooks/exhaustive-deps */
import {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';

// project imports
import config from '../../../berry/theme/config';
import { useDispatch, useSelector } from '../../../berry/store/store';
import { activeItem, openDrawer } from '../../../berry/store/slices/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// types
import { LinkTarget, NavItemType } from '../../../berry/types';
import { GenericIcon } from '../../../berry/components/MenuIcon';

interface NavItemProps {
  item: NavItemType;
  level: number;
  drawerOpen: boolean;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const MenuItem = ({ item, level, drawerOpen }: NavItemProps) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const { borderRadius } = config;
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { openItem } = useSelector((state) => state.menu);

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const itemIcon = item?.icon ? (
    <GenericIcon key={item.id} iconName={item.icon} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component:
      | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
      | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url!} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string) => {
    dispatch(activeItem([id]));
    matchesSM && dispatch(openDrawer(false));
  };

  // active menu item on page load
  useEffect(() => {
    if (`/${pathname.split('/')[1]}` === item.url) {
      dispatch(activeItem([item.id!]));
    }
  }, [pathname]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${borderRadius}px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
      }}
      selected={openItem?.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id!)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
        {itemIcon}
      </ListItemIcon>
      {drawerOpen && (
        <>
          <ListItemText
            primary={
              <Typography
                variant={
                  openItem?.findIndex((id) => id === item.id) > -1
                    ? 'h5'
                    : 'body1'
                }
                color='inherit'
              >
                {item.title}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography
                  variant='caption'
                  sx={{ ...theme.typography.subMenuCaption }}
                  display='block'
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )
            }
          />
          {item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </>
      )}
    </ListItemButton>
  );
};

export default MenuItem;
