/* eslint-disable import/no-anonymous-default-export */
export default {
  50: '#B7EACB',
  100: '#00C853',
  300: '#40C774',
  400: '#10B951',
  500: '#0EA749',
  700: '#0D9441',
  900: '#0A7533'
};
