import { memo, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// TODO Match design with figma files
import MenuList from './Menu/MenuList';
import CompanyLogo from './CompanyLogo';
import { openDrawer } from '../../berry/store/slices/menu';
import { useDispatch, useSelector } from '../../berry/store/store';
import { drawerWidth, minimizedDrawerWidth } from '../../berry/store/constants';
import LogoRanchoGrande from '../../assets/images/logo_rg.png';

// ==============================|| SIDEBAR DRAWER ||============================== //

interface SidebarProps {
  window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const logo = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: drawerOpen ? 4 : 3,
          pb: 3,
        }}
      >
        <Box component='span' sx={{ mx: 'auto' }}>
          {drawerOpen ? (
            <img
              height='76px'
              width='76px'
              src={LogoRanchoGrande}
              alt='CompanyLogo'
            />
          ) : (
            <img
              height='42px'
              width='42px'
              src={LogoRanchoGrande}
              alt='CompanyLogo'
            />
          )}
        </Box>
      </Box>
    ),
    [drawerOpen]
  );

  const drawer = useMemo(
    () => (
      <PerfectScrollbar
        component='div'
        style={{
          height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <CompanyLogo drawerOpen={drawerOpen} />
        <MenuList drawerOpen={drawerOpen} />
      </PerfectScrollbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [matchUpMd, drawerOpen]
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const navWidth = matchUpMd
    ? drawerOpen
      ? drawerWidth
      : minimizedDrawerWidth
    : 'auto';

  return (
    <Box
      component='nav'
      sx={{ flexShrink: { md: 0 }, width: navWidth }}
      aria-label='mailbox folders'
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'permanent' : 'temporary'}
        anchor='left'
        open={drawerOpen}
        onClose={() => dispatch(openDrawer(!drawerOpen))}
        sx={{
          ...(drawerOpen && {
            width: drawerWidth,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderRight: 'none',
            },
          }),
          ...(!drawerOpen && {
            width: minimizedDrawerWidth,
            '& .MuiDrawer-paper': {
              width: minimizedDrawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderRight: 'none',
            },
          }),
        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'
      >
        {logo}
        {drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);


