import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { openDrawer } from '../berry/store/slices/menu';
import { Store } from '../berry/store';
import { drawerWidth, minimizedDrawerWidth } from '../berry/store/constants';
import { DashboardStyle } from './utils';

const { useDispatch, useSelector } = Store;

const DashboardLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector(state => state.menu);
 
  React.useEffect(() => {
    dispatch(openDrawer(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  const navWidth = drawerOpen ? drawerWidth : minimizedDrawerWidth;

  const header = useMemo(
    () => (
      <Toolbar
        sx={{
          width: `calc(100% - ${navWidth}px)`,
          [theme.breakpoints.up('md')]: {
            left: `${navWidth}px`
          }
        }}>
        <Header />
      </Toolbar>
    ),
    [navWidth, theme.breakpoints]
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position='fixed'
        color='inherit'
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none'
        }}>
        {header}
      </AppBar>

      <Sidebar />

      {/* main content */}
      <DashboardStyle theme={theme} open={drawerOpen}>
        {/* breadcrumb */}
        {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
        <Outlet />
      </DashboardStyle>
    </Box>
  );
};

export default DashboardLayout;
