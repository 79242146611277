import { lazy } from 'react';
import { Loadable } from '../../berry/components';
const ROUTE_PREFIX = '/venta';
const ROUTE_CANCELAR_VENTA_PREFIX = '/cancelarVenta';

const Venta = Loadable(lazy(() => import('../../pages/Venta/Venta')));
const CancelarVenta = Loadable(lazy(() => import('../../pages/Venta/CancelarVenta')))

const VentaRoutes = [
  {
    path: `${ROUTE_PREFIX}`,
    element: <Venta />,
    title: 'Venta',
  },

  {
    path: `${ROUTE_CANCELAR_VENTA_PREFIX}`,
    element: <CancelarVenta />,
    title: 'Cancelar Venta',
  },

];

export default VentaRoutes;
