import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { AppContext, AppContextType } from '../context/AppContext';

export const AuthGuard = ({ children }: { children: React.ReactNode | null }): React.ReactElement => {
  const { isLoggedIn } = React.useContext(AppContext) as AppContextType;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoggedIn) {
      navigate('login', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const GuestGuard = (): React.ReactElement => {
  const { isLoggedIn } = React.useContext(AppContext) as AppContextType;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate('', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return <Outlet />;
};
