import * as React from 'react';
import { NavigationScroll } from './berry/layout/';
import ThemeCustomization from './berry/theme';
import AppRoutes from './routes';
import AppContextProvider from './context/AppContext';

const App = (): JSX.Element => (
  /* Add app visual providers later here. */
  <ThemeCustomization>
    <NavigationScroll>
      <AppContextProvider>
        <AppRoutes />
      </AppContextProvider>
    </NavigationScroll>
  </ThemeCustomization>
);

export default App;
