import useLocalStorage from './useLocalStorage';
import useSessionStorage from './useSessionStorage';

const TOKEN_KEY = 'TS-S-TOKEN';

//Verify if this approach is the one that we're gonna use.
export default function useToken() {
  const [localToken, setLocalToken] = useLocalStorage(TOKEN_KEY, undefined);
  const [sessionToken, setSessionToken] = useSessionStorage(
    TOKEN_KEY,
    undefined
  );

  const token = localToken ?? sessionToken;
  const setToken = (token: string | undefined, localStorage = false) => {
    localToken || localStorage ? setLocalToken(token) : setSessionToken(token);
  };
  const isLoggedIn = token !== undefined;

  return { token, setToken, isLoggedIn };
}
