import React from 'react';
import { Stack } from '@mui/material';
import LogoRanchoGrande from '../../assets/images/letra_empresa.png';

type CompanyLogoProps = {
  drawerOpen: boolean;
};

const CompanyLogo = ({ drawerOpen }: CompanyLogoProps): JSX.Element => {
  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      spacing={2}
      sx={{ mt: 2, mb: drawerOpen ? 8 : 14 }}
    >
      <img
        height={`${drawerOpen ? 80 : 30}px`}
        width={`${drawerOpen ? 160 : 70}px`}
        src={LogoRanchoGrande}
        alt='CompanyLogo'
      />
    </Stack>
  );
};

export default CompanyLogo;
