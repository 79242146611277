/* eslint-disable import/no-anonymous-default-export */
export default {
  0: '#FFFFFF',
  50: '#F1F2F4',
  100: '#E4E5E7',
  200: '#C9CCD0',
  300: '#ADB2B8',
  400: '#9298A0',
  500: '#777F89',
  600: '#5C6571',
  700: '#404B59',
  800: '#253242',
  900: '#0A182A'
};
