import { lazy } from 'react';
import { Loadable } from '../../berry/components';
const ROUTE_PREFIX = '/inicio';
const ROUTE_PREFIX_BIENVENIDA = '/bienvenido'

const Bienvenido = Loadable(lazy(() => import('../../pages/Home/Bienvenida')));
const Inicio = Loadable(lazy(() => import('../../pages/Home/Inicio')));

const InicioRoutes = [
  {
    path: `${ROUTE_PREFIX_BIENVENIDA}`,
    element: <Bienvenido />,
    title: 'Bienvenido al sistema',
  },
  {
    path: `${ROUTE_PREFIX}`,
    element: <Inicio />,
    title: 'Pagos vencidos y proximos a vencer',
  },
];

export default InicioRoutes;
