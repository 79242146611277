// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Typography } from '@mui/material';

// project imports
import ProfileSection from './Profile/ProfileSection';
import { useDispatch, useSelector } from '../../berry/store/store';
import { openDrawer } from '../../berry/store/slices/menu';
import { useLocation } from 'react-router-dom';

// assets
import { IconMenu2 } from '@tabler/icons'; //TODO ask about the icons packcage we're going to use later
import getPageTitle from '../../routes/pageTitles';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const currentLocation = useLocation();
  const titlePage = getPageTitle(currentLocation.pathname);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 36,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
          [theme.breakpoints.up('md')]: {
            margin: '-24px',
          },
        }}
      >
        <Avatar
          variant='rounded'
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
            '&:hover': {
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.light,
            },
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color='inherit'
        >
          <IconMenu2 stroke={1.5} size='1.3rem' />
        </Avatar>
      </Box>

      <Typography
        variant='h2'
        sx={{
          pl: 4,
        }}
      >
        {titlePage}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <ProfileSection />

      {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box> */}
    </>
  );
};

export default Header;
