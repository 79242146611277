import config from "../config/config";

const base_url = config.REACT_APP_API;

export const urls = {
  cliente: `${base_url}/cliente`,
  detalle : `${base_url}/clientedetalle`,
  predio: `${base_url}/predio`,
  actividad: `${base_url}/actividad`,
  agente: `${base_url}/agente`,
  amortizacion: `${base_url}/amortizacion`,
  venta: `${base_url}/venta`,
  cancelarVenta: `${base_url}/cancelarventa`,
  contrato: `${base_url}/contrato`,
  pago: `${base_url}/pago`,
  actualizarPago: `${base_url}/actualizapago`,
  usuario:`${base_url}/usuario`,
  responsabilidad: `${base_url}/responsabilidad`,
};
