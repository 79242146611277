import { lazy } from 'react';
import { Loadable } from '../../berry/components';
const ROUTE_PREFIX = '/permisos';

const Administracion = Loadable(lazy(() => import('../../pages/Configuracion/AdministrarPermiso')));

const PermisoRoutes = [
  {
    path: `${ROUTE_PREFIX}`,
    element: <Administracion />,
    title: 'Administracion de Permisos',
  },
];

export default PermisoRoutes;