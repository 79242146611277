import { lazy } from 'react';
import { Loadable } from '../../berry/components';
const ROUTE_PREFIX = '/clientes';

const Clients = Loadable(lazy(() => import('../../pages/Cliente/Cliente')));

const ClientRoutes = [
  {
    path: `${ROUTE_PREFIX}`,
    element: <Clients />,
    title: 'Clientes',
  },
];

export default ClientRoutes;
