import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, AppContextType } from '../context/AppContext';

const InitialPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { isLoggedIn } = React.useContext(AppContext) as AppContextType;
  React.useEffect(() => {
    //TODO Change the initial page if needed
    navigate(isLoggedIn ? 'bienvenido' : 'login', { replace: true });
  }, [navigate, isLoggedIn]);

  return <p>TODO CREATE A 403 page</p>;
};

export default InitialPage;
