import { createSlice } from '@reduxjs/toolkit';
import { IUsuario, UsuarioStateProps } from '../../../services/dto/PermisoDto';
import { getPermisoUsuario } from "../../../services/permiso.service";
import { urls } from "../../../utils/url";
import { dispatch } from "../store";

const initialState: UsuarioStateProps = {
  error: null,
  usuario: {
    usuario: {
      email: "",
      id: "",
      name: "",
      permisos: [],
      menu: [],
      providerName: "",
    },
  },
};

// ==============================|| SLICE - MENU ||============================== //

const permisouser = createSlice({
  name: 'permiso',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    updatePermiso(state, action) {
      return {
        ...state,
        usuario: action.payload,
      };
    },
  }
});

export function obtenerPermiso() {
  return async () => {
    const res = await getPermisoUsuario(`${urls.usuario}`);

    if (res.code !== "200") {
     return dispatch(hasError(res));
    } else {

      const data:IUsuario = res.data;
      const orderArray =  data.usuario.menu.sort((menu, siguiente) => menu.orden - siguiente.orden);
      data.usuario.menu = orderArray;
     return dispatch(updatePermiso(data));
    }
  };
}

export default permisouser.reducer;

export const { hasError, updatePermiso } = permisouser.actions;
