import useLocalStorage from './useLocalStorage';

const USER_KEY = 'TS-PERMISO';

export default function usePermiso() {
  const [permisos, setPermisos] = useLocalStorage(
    USER_KEY,
    undefined
  );

  return { permisos, setPermisos };
}