import { lazy } from 'react';
import { Loadable } from '../../berry/components';
const ROUTE_PREFIX = '/predios';

const Predio = Loadable(lazy(() => import('../../pages/Predio/Predio')));

const PredioRoutes = [
  {
    path: `${ROUTE_PREFIX}`,
    element: <Predio />,
    title: 'Predios',
  },
];

export default PredioRoutes;
