import { List } from "@mui/material";

import MenuItem from "./MenuItem";

import { useSelector } from "../../../berry/store/store";

type MenuListProps = {
  drawerOpen: boolean;
};

const MenuList = ({ drawerOpen }: MenuListProps) => {
   const { usuario } = useSelector((state) => state.permisouser);

  const navItems = usuario.usuario.menu.map((item) => (
    <MenuItem key={item.id} item={item} level={1} drawerOpen={drawerOpen} />
  ));

  return <List>{navItems}</List>;
};

export default MenuList;