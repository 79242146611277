import { HttpResponse } from '../utils/httpRespose';

const getTokens = () => {
  const localStorageToken = localStorage.getItem('TS-S-TOKEN')?.replace(/"/g, '');
  const sessionStorageToken = sessionStorage
    .getItem('TS-S-TOKEN')
    ?.replace(/"/g, '');

  return { localStorageToken, sessionStorageToken };
}


const getHeders = () => {
  const { localStorageToken, sessionStorageToken } = getTokens();

  const HeadersRequest = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorageToken !== undefined ? localStorageToken : sessionStorageToken
      }`,
  };

  return HeadersRequest;
}

const MethodRequest = {
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
  get: 'GET',
};

const formatError = (err: TypeError): Error => {
  return new Error(err ? `${err?.message} ${err?.stack}` : 'Unknow error');
};

export const get = async <T>(url: string): Promise<HttpResponse<T>> => {
  const HeadersRequest = getHeders();

  try {
    const response = await fetch(url, {
      method: MethodRequest.get,
      headers: HeadersRequest,
    });
    const dataResponse = await response.json();
    return dataResponse;
  } catch (err) {
    throw formatError(err as TypeError);
  }
};

export const post = async <T1, T2>(
  url: string,
  requestBody: T1
): Promise<HttpResponse<T2>> => {
  const HeadersRequest = getHeders();
  try {
    const response = await fetch(url, {
      method: MethodRequest.post,
      headers: HeadersRequest,
      body: JSON.stringify(requestBody),
    });

    const dataResponse = await response.json();
    return dataResponse;
  } catch (err) {
    throw formatError(err as TypeError);
  }
};

export const patch = async <T1, T2>(
  url: string,
  requestBody: T1
): Promise<HttpResponse<T2>> => {
  const HeadersRequest = getHeders();
  try {
    const response = await fetch(url, {
      method: MethodRequest.patch,
      headers: HeadersRequest,
      body: JSON.stringify(requestBody),
    });

    const dataResponse = await response.json();
    return dataResponse;
  } catch (err) {
    throw formatError(err as TypeError);
  }
};

export const put = async <T1, T2>(
  url: string,
  requestBody: T1
): Promise<HttpResponse<T2>> => {
  try {
    const response = await fetch(url, {
      method: MethodRequest.put,
      //headers: HeadersRequest,
      body: JSON.stringify(requestBody),
    });

    const dataResponse = await response.json();
    return dataResponse;
  } catch (err) {
    throw formatError(err as TypeError);
  }
};

export const remove_url = async <T>(url: string): Promise<HttpResponse<T>> => {
  const HeadersRequest = getHeders();
  try {
    const response = await fetch(url, {
      method: MethodRequest.delete,
      headers: HeadersRequest,
    });
    const dataResponse = await response.json();
    return dataResponse;
  } catch (err) {
    throw formatError(err as TypeError);
  }
};

export const remove = async <T1, T2>(
  url: string,
  requestBody: T1
): Promise<HttpResponse<T2>> => {
  const HeadersRequest = getHeders();
  try {
    const response = await fetch(url, {
      method: MethodRequest.delete,
      headers: HeadersRequest,
      body: JSON.stringify(requestBody),
    });

    const dataResponse = await response.json();
    return dataResponse;
  } catch (err) {
    throw formatError(err as TypeError);
  }
};


