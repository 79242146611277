import { lazy } from 'react';
import { Loadable } from '../berry/components';
import { GuestGuard } from './RouteGuards';

const Login = Loadable(lazy(() => import('../pages/Login/Login')));
//TODO FORGOT PASSWORD

const LoginRoutes = {
  path: '/',
  element: <GuestGuard />,
  children: [
    {
      path: '/login',
      element: <Login />
    }
  ]
};

export default LoginRoutes;
