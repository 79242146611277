import { lazy } from 'react';
import { Loadable } from '../../berry/components';
const ROUTE_PREFIX = '/reporte/vencidos';
const ROUTE_PREFIX_INFORME = '/reporte/informe';
const ROUTE_PREFIX_REALIZADO = '/reporte/realizados';
const ROUTE_PREFIX_RESPONSABILIDAD = '/reporte/responsabilidades';

const Reporte = Loadable(lazy(() => import('../../pages/Reporte/Reporte')));
const InformeCliente = Loadable(lazy(() => import('../../pages/Reporte/InformeCliente')));
const PagosRealizados = Loadable(lazy(() => import('../../pages/Reporte/PagoRealizado')));
const PagosResponsabilidad = Loadable(lazy(() => import('../../pages/Reporte/ReporteResponsabilidades')));

const ReporteRoutes = [
  {
    path: `${ROUTE_PREFIX}`,
    element: <Reporte />,
    title: 'Pagos vencidos',
  },
  {
    path: `${ROUTE_PREFIX_REALIZADO}`,
    element: <PagosRealizados />,
    title: 'Pagos Realizados',
  },
  {
    path: `${ROUTE_PREFIX_INFORME}`,
    element: <InformeCliente />,
    title: 'Informe Por Cliente',
  },
  {
    path: `${ROUTE_PREFIX_RESPONSABILIDAD}`,
    element: <PagosResponsabilidad />,
    title: 'Reporte General de responsabilidades',
  },
];

export default ReporteRoutes;
