import * as icons from '@mui/icons-material';

export type IconNames = keyof typeof icons; // use this in other components

interface IGenericIconProps {
  iconName: IconNames;
}

export const GenericIcon = ({ iconName }: IGenericIconProps): JSX.Element => {
  const Icon = icons[iconName];
  return <Icon />;
};