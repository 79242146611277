import * as React from 'react';
import { NavItemType } from '../berry/types';
import { usePermiso, useToken, useUser } from '../hooks';

export type AppContextType = {
  appToken: string | undefined;
  setAppToken: (token: string | undefined, localStorage?: boolean) => void;
  isLoggedIn: boolean;
  appUser: string | undefined;
  setAppUser: (userId: string | undefined, localStorage?: boolean) => void;
  permisos: NavItemType[];
  setPermisos: (permisos: NavItemType[]) => void;
};

export const AppContext = React.createContext<AppContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const AppContextProvider: React.FC<Props> = ({ children }) => {
  const { token: appToken, setToken: setAppToken, isLoggedIn } = useToken();
  const { user: appUser, setUser: setAppUser } = useUser();
  const { permisos, setPermisos } = usePermiso();
  
  return <AppContext.Provider value={{ appToken, setAppToken, isLoggedIn, appUser, setAppUser, permisos, setPermisos }}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
