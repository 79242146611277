import DashboardLayout from '../layout/Dashboard';
import * as Routes from './dashboardRoutes/';
import { AuthGuard } from './RouteGuards';

const DashboardRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  children: [
    ...Routes.Inicio,
    ...Routes.Clients,
    ...Routes.Predio,
    ...Routes.Pagos,
    ...Routes.Reportes,
    ...Routes.Venta,
    ...Routes.Permisos,
  ],
};

export default DashboardRoutes;
